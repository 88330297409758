<template>
    <div class="sendCode h-font-lg" id="sendCode">
        <div class="sendCode-title">
            <span>发送验证码</span>
        </div>
        <div class="base h-group car-des">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title" style="font-size:20px">{{ plateNoName }}</div>
                <div class="h-cell__value">
                </div>
            </div>
            <div class="h-cell__content">
                <div class="warn">
                <i class="van-icon van-icon-warning" /> {{ riskDesc }}
                </div>
            </div>
        </div>
        <div class="compulsory h-group">
            <div class="h-cell__content">
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">手机号码</van-col>
                    <van-col span="18" v-if="paramsData.holder && paramsData.holder.mobile">{{ paramsData.holder.mobile }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6" class="h-text-color_gray-6" style="margin-top: 0.07rem;">
                        <span style="color: red; margin-right: 4px;">*</span>
                        <span>验证码</span>
                    </van-col>
                    <van-col span="18">
                        <van-field
                            v-model="codeVal"
                            clearable
                            label=""
                            placeholder="请输入验证码"
                            maxlength="6"
                        >
                        <template #button>
                            <van-button type="primary" size="mini" :loading="sendCodeLoading" loading-text="发送中..." @click="getCode">获取验证码</van-button>
                        </template>
                        </van-field>
                    </van-col>
                </van-row>
            </div>
        </div>
        
        <div class="quote-result_bnt">
            <div class="quote-result_bnt--fixed success">
                <van-button type="primary" style="margin-right: 40px;" block round @click="updateConfirm">确定</van-button>
                <van-button type="default" style="border: 1px solid rgb(87, 204, 194); color: rgb(87, 204, 194);" block round @click="back">取消</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from 'dayjs'
import { Notify, Toast } from 'vant'
export default {
  name: 'sendCode',
  data() {
    return {
        resData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        paramsData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        permBtns: [],
        codeVal: '',
        sendCodeLoading: false
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"]),
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(this.quote.vehicle.plateFlag){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial && this.quote.commercial.riskList){
        commercialFlag = true
        const commercialList= this.quote.commercial.riskList && this.quote.commercial.riskList.map(item=>item.riskName)
        commercialNum = commercialList && commercialList.length
        commercialStr=`商业险(${commercialList && commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项`
    },
  },
  async created() {
  },
  mounted() {
    sendCode.style.height = (document.documentElement.clientHeight - 60) + 'px'
    this.paramsData = this.quote
    if (!this.paramsData.holder.mobile) {
        this.paramsData.holder.mobile = this.paramsData.insured.mobile
    }
  },
  methods: {
    dayjs,
    back () {
        this.$router.go(-1)
    },
    updateConfirm () {
        if(!this.codeVal){
            Notify({ type: 'warning', message: '请输入验证码' });
            return false
        }
        let setInter = setInterval(() => {
            Toast.loading({
            forbidClick: true
            }, 500)
        })
        request({
            url: '/afis-api-manage/invoke/core/auto/smsVerifyCodeConfirmIn',
            method: 'post',
            data: {
                subBusinessNo: this.quote.basis.subBusinessNo || this.paramsData.basis.subBusinessNo,
                verifyCode: this.codeVal
            }
        }).then(e => {
            clearInterval(setInter)
            Toast.success('操作成功')
            this.$router.go(-1)
        }).catche(e => {
            clearInterval(setInter)
        })
    },
    async getCode () {
        this.sendCodeLoading = true
        try {
            await request({
                url: '/afis-api-manage/invoke/core/auto/sendVerifyCodeIn',
                method: 'post',
                data: {
                    subBusinessNo: this.quote.basis.subBusinessNo || this.paramsData.basis.subBusinessNo,
                }
            })
            Toast.success('验证码已发送')
            this.sendCodeLoading = false
        } catch (e) {
            this.sendCodeLoading = false
        }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.sendCode {
    background: url('./image/bg-title.png') no-repeat fixed;
    background-size: 100% 100%;
    padding: 0 8px;
    .sendCode-title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        padding: 14px 0 20px 0px;
        .van-icon {
            font-weight: bold;
            margin-left: 6px;
            position: relative;
            top: 2px;
        }
    }
    .h-cell__content{
        padding: 6px 8px;
        font-size: $font-size-sm;
        ::v-deep .van-row {
            padding:9px 0;
        }
    }
    .h-cell__title{
        font-weight: $font-weight-bold-lg;
        font-size: $font-size-md;
        span.icon {
            border-radius: $border-radius-sm;
            color: $white;
            padding: 3px;
            margin-right:6px;
        }
    }
    .h-cell{
        padding: 10px 8px;
    }
    .h-cell__value {
        color: #0066FF;
        font-size: 12px;
    }
    .base{
        .warn{
            text-indent: -4px;
            padding-left: 12px;
            font-size: $font-size-xs;
            color: $primary-color;
        }
    }
    .h-group{
        margin-bottom: 10px;
        padding: 8px;
        border-radius: $border-radius-lg;
    }
    .car-des {
        .refresh-des {
            margin-right: 4px;
        }
        .van-icon {
            font-size: 14px;
        }
    }
    .insured-msg {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
            button {
                width: 40%;
            }
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
        button {
            width: 100px;
            display: inline-block;
        }
    }
    .van-cell {
        padding-bottom: 0;
        padding-top: 0;
    }
}
</style>
